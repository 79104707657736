<template>
    <!-- <v-app>
        <v-layout justify-center align-center column class="grey lighten-2">
            <div>
                <v-card dark color="primary" tile class="pa-4">
                    <v-card-subtitle class="pb-0"> bright </v-card-subtitle>
                    <v-card-title>시스템 점검 공지</v-card-title>
                    <v-card-text>
                        안녕하세요, 고객님. <br />
                        시스템 점검으로 서비스 이용이 아래와 같이 중지됩니다. <br />
                        이용에 불편을 드려 죄송합니다.
                    </v-card-text>
                </v-card>
                <v-card tile color="grey lighten-5" light class="pa-4">
                    <v-card-title class="pb-0"> 이용제한 서비스 </v-card-title>
                    <v-card-text> 회원가입, 로그인, 상품주문 등 서비스 일체 </v-card-text>
                    <v-divider class="grey lighten-3" />
                    <v-card-title class="pb-0"> 작업일시 </v-card-title>
                    <v-card-text> 2025년 1월 21일 (화) 20:00 ~ 21:00 (1시간) </v-card-text>
                </v-card>
            </div>
        </v-layout>
    </v-app> -->
    <router-view />
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    metaInfo() {
        return Object.assign({}, this.$data.seo);
    },
    data: () => ({
        seo: {},
        timer: null,
        latest: Date.now(),
    }),
    computed: {
        ...mapState(["likes", "user"]),
        refreshToken() {
            return this.$store.state.refreshToken;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return JSON.parse(atob(this.refreshToken?.split(".")[1] || "") || "{}");
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.getSetting__v1();
        this.getIslands();
        this.getShipping__everyAsset();
        this.getCategories();
        if (this.accessToken) {
            if (!this.user) this.getUser();
            if (!this.likes) this.getLikes();
        }
    },
    watch: {
        accessToken() {
            if (!this.accessToken) {
                alert("로그아웃 되었습니다");
                this.$router.push("/");
            } else {
                if (!this.user) this.getUser();
                if (!this.likes) this.getLikes();
            }
        },
    },
    methods: {
        ...mapActions(["getSetting__v1", "getUser", "getLikes", "getIslands", "getShipping__everyAsset", "getCategories"]),

        init() {
            document.addEventListener("mousemove", this.reset, false);
            document.addEventListener("mousedown", this.reset, false);
            document.addEventListener("keypress", this.reset, false);
            document.addEventListener("touchmove", this.reset, false);
            document.addEventListener("onscroll", this.reset, false);

            this.onTimer();
        },
        reset() {
            this.latest = Date.now();
        },
        onTimer() {
            clearTimeout();
            this.timer = setTimeout(() => {
                if (24 * 60 * 60 * 1000 < Date.now() - this.latest || this.payload.exp * 1000 <= Date.now()) {
                    this.$store.dispatch("logout");
                }
                this.onTimer();
            }, 1000);
        },
    },
};
</script>
